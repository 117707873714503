import request from '@/utils/request'

export const getAiData = (params: any) => {
  return request({
    headers: {
      userURL:
        process.env.VUE_APP_BASE_API === '/prod-api'
          ? `${process.env.VUE_APP_TARGET_URL}/prod-api`
          : process.env.VUE_APP_TARGET_URL
    },
    url: '/external/exception/getId',
    method: 'get',
    params
  })
}

export const saveReview = (data: any) => {
  return request({
    headers: {
      userURL:
        process.env.VUE_APP_BASE_API === '/prod-api'
          ? `${process.env.VUE_APP_TARGET_URL}/prod-api`
          : process.env.VUE_APP_TARGET_URL
    },
    url: '/external/exception/update',
    method: 'post',
    data
  })
}

export const getExceptionInfo = (token: string) => {
  return request({
    headers: {
      userURL:
        process.env.VUE_APP_BASE_API === '/prod-api'
          ? `${process.env.VUE_APP_TARGET_URL}/prod-api`
          : process.env.VUE_APP_TARGET_URL
    },
    url: '/external-api/external/exception/info/byToken?token=' + token,
    method: 'get'
  })
}
